<template>
    <div class="goods-details">
        <mt-swipe :auto="3000" class="swiper">
            <mt-swipe-item v-for="(item, index) in detail.show_imgurl" :key="index">
                <img :src="item" />
            </mt-swipe-item>
        </mt-swipe>
        <div class="goods-desc">
            <div class="goods-price">
                {{ detail.buy_points }}
                <span>积分</span>
                <i>￥{{ detail.market_price }}</i>
            </div>
            <div class="goods-name">{{ detail.goods_name }}</div>
        </div>
        <div class="num-row">
            <div class="num-title">数量</div>
            <van-stepper
                v-model="buyNum"
                min="1"
                :max="detail.goods_stock"
                :integer="true"
                input-width="2.2rem"
                button-size="0.58rem"
                @overlimit="overlimit"
            />
        </div>
        <div class="content">
            <div class="content-title">图文详情</div>
            <div v-html="detail.long_contents" class="content-box"></div>
        </div>
        <div :class="['btn',{'disable': !isCanBuy}]" @click="exchange">{{isCanBuy?'立即兑换':'积分不足'}}</div>

        <div class="model" v-if="isShowSpec">
            <div class="model-box">
                <div class="goods-info">
                    <img src="@/assets/goods.jpg" class="goods-img" />
                    <div class="goods-desc-box">
                        <div class="goods-name">北欧灯具全铜客厅灯分子灯简约现代风格套餐创意餐厅卧室魔豆吊灯</div>
                        <div class="goods-price">
                            1600
                            <span>积分</span>
                        </div>
                    </div>
                </div>

                <div class="spec-box">
                    <div class="spec-title">规格</div>
                    <div class="spec-item ac">皮质1.8*2m</div>
                    <div class="spec-item">皮质2*2.3m</div>
                    <div class="spec-item">布质1.5*1.8m</div>
                    <div class="spec-item">布质2*2.3m</div>
                </div>

                <div class="num-row">
                    <div class="num-title">数量</div>

                    <div class="goods-num">
                        <span class="plus">+</span>
                        <div>1</div>
                        <span class="minus">-</span>
                    </div>
                </div>

                <div class="tips">积分不足，快去商城下单赚积分吧~</div>

                <div class="model-btn gray">立即兑换</div>
            </div>
            <div class="pop" @click="isShowSpec = false"></div>
        </div>

        <div class="model1" v-if="isShowPassTips">
            <div class="msg-box">
                <img class="close-img" src="@/assets/close1.png" alt />
                <div class="msg-title">交易密码</div>
                <div class="msg">您当前并未设置交易密码，请先前往设置~</div>
                <div class="msg-link">前往设置</div>
            </div>
            <div class="pop" @click="isShowPassTips = false"></div>
        </div>
    </div>
</template>

<script>
import { getIntegral, getIntegralGoodsDetails, checkCards } from "@/http/api";

export default {
    components: {},
    data() {
        return {
            goods_id: this.$route.query.id,
            detail: {},
            isShowSpec: false,
            isShowPassTips: false,
            buyNum: 1
        };
    },
    mounted() {
        if ((this.userInfo = this.$login.isLogin(window.location.href))) {
            this.getIntegral();
            this.getIntegralGoodsDetails();
        }
    },
    watch: {
        buyNum(newV, oldV) {}
    },
    methods: {
        async getIntegral() {
            const res = await getIntegral({
                user_id: this.userInfo.user_id
            });
            // console.log(res);
            this.integral = res.integral;
        },
        //获取商品详情
        async getIntegralGoodsDetails() {
            const res = await getIntegralGoodsDetails({
                user_id: this.userInfo.user_id,
                goods_id: this.goods_id
            });
            // console.log(res);
            this.detail = res;
        },
        // 商品预结算
        async checkCards() {
            const res = await checkCards({
                data: {
                    user_token: this.userToken,
                    goods_id: this.goods_id,
                    goods_nums: this.buyNum,
                    goods_specs_id: 0
                }
            });
            if (res.code == 200) {
                //跳转商品结算页面
                this.$router.push({
                    path: "/PointsMallSettlement",
                    query: {
                        id: res.data.buffer_id
                    }
                });
            } else {
                this.$toast.fail(res.msgs);
                // 处理错误
            }
        },
        //立即兑换
        exchange() {
            if (this.isCanBuy) {
                this.checkCards();
            } else {
                this.$toast.fail("积分不足");
            }
        },
        overlimit(e) {
            if (e == "minus") {
                this.$toast("最少购买一个");
            } else {
                this.$toast("库存不足");
            }
        }
    },
    computed: {
        isCanBuy() {
            let integral = this.integral,
                detail = this.detail,
                total = detail.buy_points * this.buyNum;
            if (integral && detail) {
                if (integral < total) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    },
    filters: {}
};
</script>

<style lang="scss" scoped>
.goods-details {
    box-sizing: border-box;
    .swiper {
        height: 100vw;
    }
    .mint-swipe-item img {
        height: 100vw;
        object-fit: cover;
    }
    .goods-desc {
        padding: 3vw;
        border-bottom: 10px solid #f6f6f6;
        .goods-price {
            color: #da1313;
            font-size: 0.32rem;
            font-weight: 600;
            margin-bottom: 10px;
            span {
                font-size: 0.24rem;
                font-weight: 400;
            }
            i {
                font-size: 0.24rem;
                color: #a3a3a3;
                text-decoration: line-through;
            }
        }
        .goods-name {
            font-size: 0.3rem;
            line-height: 1.6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
    .num-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.24rem;
        border-bottom: 10px solid #f6f6f6;
        .num-title {
            font-size: 0.28rem;
            color: #323232;
        }
        .goods-num {
            display: flex;
            align-items: center;
            height: 0.7rem;
            border: 1px solid #eee;
            font-size: 0.36rem;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 0.7rem;
                width: 1rem;
            }
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 0.7rem;
                width: 1.26rem;
                font-size: 0.26rem;
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;
            }
        }
    }
    .content {
        .content-title {
            box-sizing: border-box;
            height: 1.16rem;
            width: 100vw;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: flex-end;
            padding: 0 0 0.3rem 3vw;
            font-size: 0.3rem;
            margin-bottom: 10px;
        }
        .content-box {
            font-size: 0.26rem;
        }
        img {
            width: 100vw !important;
        }
    }
    .btn {
        width: 90.8vw;
        height: 0.84rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 4.1vw;
        bottom: 3vw;
        font-size: 0.3rem;
        color: #fff;
        background: #e50012;
        border-radius: 0.84rem;
        &.disable {
            background-color: #dfdfdf;
        }
    }
    .model {
        // display: none;
        background: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        .pop {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1001;
        }
        .model-box {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100vw;
            background: #fff;
            padding: 3vw;
            box-sizing: border-box;
            z-index: 1002;
            .goods-info {
                display: flex;
                align-items: center;
                .goods-img {
                    width: 22.8vw;
                    height: 22.8vw;
                    border-radius: 5px;
                    margin-right: 3vw;
                }
                .goods-desc-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 22.8vw;
                    .goods-name {
                        font-size: 0.28rem;
                        line-height: 1.6;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .goods-price {
                        color: #da1313;
                        font-size: 0.32rem;
                        font-weight: 600;
                        margin-bottom: 10px;
                        span {
                            font-size: 0.24rem;
                            font-weight: 400;
                        }
                    }
                }
            }
            .spec-box {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .spec-title {
                    font-size: 0.28rem;
                    color: #323232;
                    width: 100%;
                    height: 0.92rem;
                    display: flex;
                    align-items: center;
                }
                .spec-item {
                    padding: 0 0.35rem;
                    border-radius: 5px;
                    background: #f1f1f1;
                    color: #000;
                    font-size: 0.26rem;
                    height: 0.63rem;
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
                .spec-item.ac {
                    background: #fce0e0;
                }
            }
            .tips {
                color: #e50012;
                font-size: 0.26rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0;
            }
            .model-btn {
                width: 90.8vw;
                height: 0.84rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 3vw 1.1vw;
                font-size: 0.3rem;
                color: #fff;
                background: #e50012;
                border-radius: 0.84rem;
            }
            .model-btn.gray {
                background: #e6e4e4;
                color: #fff;
            }
        }
    }
    .model1 {
        // display: none;
        background: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        .msg-box {
            position: absolute;
            top: 16vh;
            left: 8vw;
            width: 84vw;
            background: #fff;
            border-radius: 5px;
            .close-img {
                width: 0.65rem;
                height: 0.65rem;
                position: absolute;
                top: -0.3rem;
                right: -0.3rem;
            }
            .msg-title {
                background: #ff6521;
                color: #fff;
                font-size: 0.36rem;
                height: 0.9rem;
                width: 100%;
                text-align: center;
                line-height: 0.9rem;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            .msg {
                font-size: 0.28rem;
                line-height: 1.88rem;
                text-align: center;
            }
            .msg-link {
                background: #e50112;
                color: #fff;
                font-size: 0.3rem;
                height: 0.75rem;
                width: 1.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                margin: 0 auto 0.55rem;
            }
        }
    }
}
</style>
